import Loadable from 'components/Loadable';
import { lazy } from 'react';


const Brands = Loadable(lazy(() => import('./Brands')));
const CreateBrand = Loadable(lazy(() => import('./_pages/CreateBrand')));
const BrandDetails = Loadable(lazy(() => import('./_pages/BrandDetails')));
const UpdateBrand = Loadable(lazy(() => import('./_pages/UpdateBrand')));

export const brandsRoutes = [
  { path: '/brands', element: <Brands /> },
  { path: '/brands/create', element: <CreateBrand /> },
  { path: '/brands/details/:id', element: <BrandDetails /> },
  { path: '/brands/update/:id', element: <UpdateBrand /> },
];
