import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import repeatIcon from '@iconify/icons-eva/repeat-outline';
import shieldOff from '@iconify/icons-eva/shield-off-outline';
import optionsIcon from '@iconify/icons-eva/options-2-outline';

import { Icon } from '@iconify/react';

import { toast } from 'react-toastify';

import Checkbox from '@mui/material/Checkbox';


import Page from 'components/Page';

import http from 'common/http';
import { apiURL } from 'common';
import { useSettings } from 'hooks/settings/useSettings';
import { useTheme } from 'styled-components';



const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const SettingsPage = () => {
  const theme = useTheme()

  let settings = {}
  const [loading, setLoading] = useState(false);
  //  const [registration, setRegistration] = useState(false);
  //  const [settings, setSettings] = useState({})

  const settingsInfo = useSettings();
  

  if (settingsInfo.settings && !settingsInfo.loading) {
    settings = settingsInfo.settings;
  }
  
  const handleAllowRegistration = async (registration) => {
    const value = !registration;
    try {
        //  setRegistration(value);
        const url = `${apiURL}/admin/settings`
        // console.log(value, 'registration');
        const { data } = await http.put(url, { registration: value });
        // console.log(data)
        if (data.state) {
          settingsInfo.mutate();
          toast.success('Updated user registration')
        } else {
          throw(new Error(data.msg))
        }
    } catch (err) {
      toast.error(err.message)
    }
  }

  const rebuildWebsite = async () => {
    try {
      setLoading(true);
      const { data } = await http.get('/rebuild-website');

      if (data.state) {
        toast.success('Website rebuilding in progress');
      } else {
        throw new Error('Failed to rebuild');
      }
    } catch (err) {
      toast.error('Error initiating rebuild');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title="Settings">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4" sx={{ color: theme?.palette?.primary?.main }}>
            System configuration
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Demo>
              <RenderSettings settingsInfo={settingsInfo} handleAllowRegistration={handleAllowRegistration} rebuildWebsite={rebuildWebsite} />
            </Demo>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};


const RenderSettings = ({ settingsInfo, handleAllowRegistration, rebuildWebsite }) => {
  const { loading, error, settings, msg } = settingsInfo;

  if (loading) {
    return (<div>loading...</div>)
  }
  if (error) {
    return (
      <div>
        <h5>Error loading settings</h5>
        <p> {msg} </p>
      </div>
    )
  }

  
  return (
    <List dense={true}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      onClick={rebuildWebsite}
                      edge="end"
                      aria-label="delete"
                    >
                      {getIcon(repeatIcon)}
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>{getIcon(optionsIcon)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Update website" />
                </ListItem>

                <ListItem
                  sx={{
                    mt: 2
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="Allow user registration"
                    >
                       <Checkbox checked={settings.registration} value={settings.registration}  onChange={() => handleAllowRegistration(settings.registration)} />
                    </IconButton>
                  }
                >

                <ListItemAvatar>
                    <Avatar>
                    {getIcon(shieldOff)}

                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Allow user registration" />

                </ListItem>
              </List>
  )
}

export default SettingsPage;
