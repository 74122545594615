import useSWR from 'swr';

import { apiURL } from 'common/index';

export const usePages = () => {
  const url = `${apiURL}/admin/page/`;
  const { error, data, mutate } = useSWR(url);

  return {
    loading: !error && !data,
    error,
    pages: data && data.state ? data.pages : [],
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};

export default usePages;