// import Signup from './SignUp';
// import Login from './Login';
import Loadable from 'components/Loadable';
import { lazy } from 'react';

const Login = Loadable(lazy(()=> import('./Login')))
const SignUp = Loadable(lazy(() => import('./SignUp')))
const ResetPassword = Loadable(lazy(() => import('./ResetPassword')))
const NewPassword = Loadable(lazy(() => import('./NewPassword')))

// Loadable(lazy(() => import('layouts/dashboard')));

export const authRoutes = [
  { path: 'signup', element: <SignUp /> },
  { path: '/auth/login', element: <Login /> },
  { path: '/auth/reset', element: <ResetPassword /> },
  { path: '/auth/new-password', element: <NewPassword /> }
];
