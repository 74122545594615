import { useState, createContext, useEffect, useCallback } from 'react';

import http from 'common/http';

export const CountryContext = createContext();

export const CountryContextProvider = (props) => {
  const [countries, setCountries] = useState([]);

  const initializeCountries = async () => {
    const { data } = await http.get('/admin/country');
    if (data.state) {
      setCountries(data.countries);
    }
  };

  const addCountry = useCallback((country) => {
    setCountries([...countries, country]);
  }, []);

  useEffect(() => {
    initializeCountries();
  }, []);

  const loadCountries = useCallback((_countries) => {
    setCountries(_countries);
  }, []);

  const deleteCountry = useCallback( (country) => {
    setCountries(countries.filter((b) => b._id === country._id));
  }, [countries]);

  return (
    <CountryContext.Provider
      value={{ countries, loadCountries, deleteCountry, addCountry }}
    >
      {props.children}
    </CountryContext.Provider>
  );
};
