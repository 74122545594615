import Loadable from 'components/Loadable';
import { lazy } from 'react';

import { AppPaths } from 'routes/paths'

const GalleryPage = Loadable(lazy(()=> import('./Gallery')));


const _paths = AppPaths.general.gallery


export const galleryRoutes = [
    { path: _paths.root, element: <GalleryPage /> },
];