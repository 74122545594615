import { useState } from 'react';
//   import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';

import { format } from 'date-fns';

import { toast } from 'react-toastify';

import Page from 'components/Page';
import LoadingScreen from 'components/loading-screen'

import { useParams } from 'react-router-dom';

import { useOrderDetails, useOrders } from 'hooks/orders';

import CartItems from './_components/CartItems';

import http from 'common/http';
import { ErrorBoundary } from 'react-error-boundary';
import { formatCurrency } from 'common/methods';
import InvoiceDetails from './_components/details';

export const OrderDetailsPage = () => {
  const [loader, setLoader] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [deliveryLoader, setDeliveryLoader] = useState(false);

  const { id } = useParams();
  const { loading, order, error, mutate } = useOrderDetails(id);
  const ordersHook = useOrders();

  const theme = useTheme();

  const markDelivered = async (id) => {
    // loaded
    try {
      setActiveId(id);
      setDeliveryLoader(true);
      const { data } = await http.get(`/admin/order/close/${id}`);
      if (data.state) {
        toast.success('Marked as delivered');
        mutate();
        ordersHook.mutate();
      } else {
        throw new Error('Error');
      }
    } catch (err) {
      toast.error('Failed to mark delivered');
    } finally {
      setDeliveryLoader(false);
      setActiveId(null);
    }
  };

  const cancelOrder = async (id) => {
    try {
      setActiveId(id);
      setLoader(true);
      const { data } = await http.get(`/admin/order/cancel/${id}`);
      if (data.state) {
        mutate();
        toast.success('Order canceled successfully');
      } else {
        throw new Error('Failed');
      }
    } catch (err) {
      toast.error('Error canceling failed');
    } finally {
      setLoader(false);
      setActiveId(null);
    }
  };

  if (loading) {
    return <div style={{ position: 'relative', overflow: 'hidden' }}>
      <LoadingScreen />
    </div>;
  }
  if (error) {
    return <div>Error loading data </div>;
  }



  return (
    <Page title="Order details">
      <ErrorBoundary fallback={<div> Error displaying order data correctly </div>}>
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4" sx={{ color: theme.palette.primary.main }} className="">
              Order details
            </Typography>
          </Box>
          <Grid container>
            <Grid
              item
              container
              xs={12}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => cancelOrder(order._id)}
                color="secondary"
                sx={{ m: 1 }}
              >
                {loader ? 'Canceling...' : order.canceled ? 'Canceled' : 'Cancel'}
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ m: 1 }}
                onClick={() => markDelivered(order._id)}
              >
                {deliveryLoader
                  ? 'Loading...'
                  : order.delivered
                  ? 'Delivered'
                  : 'Mark delivered'}
              </Button>
            </Grid>

            <br />

            
                {/* <div> {typeof(order?.placedOn)} </div> */}
            {/* <Grid item>
              <div>From: {order?.client?.name} </div>
              <div>Phone: {order?.client?.phone} </div>
              <div>Email: {order?.client?.email} </div>
              <br />
              <div>Subtotal: {formatCurrency(order.subtotal)} </div>
              <div>Delivery location: {order?.delivery?.location ?? order.location } </div>
              <div>Road: {order?.delivery?.road} </div>
              <div>Placed on: {new Date(order.createdAt).toDateString()} </div>
            </Grid>
            <Grid xs={12} item>
              <CartItems products={order.products} />
            </Grid> */}
            {/* {JSON.stringify(order)} */}

            <Grid item xs={12} sx={{ mt: 3 }}>
              <InvoiceDetails invoice={order} />
            </Grid>
          </Grid>
        </Container>
      </ErrorBoundary>
    </Page>
  );
};

export default OrderDetailsPage;
