import Loadable from 'components/Loadable';
import { lazy } from 'react';

import { AppPaths } from 'routes/paths'

const FAQs = Loadable(lazy(()=> import('./Faqs')));
const CreateFaq = Loadable(lazy(()=> import('./_pages/CreateFaq')));
const FaqDetail = Loadable(lazy(()=> import('./_pages/FaqDetail')));
const UpdateFaq = Loadable(lazy(()=> import('./_pages/UpdateFaq')));


const _paths = AppPaths.marketing.faqs


export const faqsRoutes = [
    { path: _paths.list, element: <FAQs /> },
    { path: _paths.create, element: <CreateFaq /> },
    { path: _paths.details, element: <FaqDetail /> },
    { path: _paths.edit, element: <UpdateFaq /> },
];