import Loadable from 'components/Loadable';
import { lazy } from 'react';

//  import SEO from './SEO';
//  import CreatePage from './_pages/CreateRecord';
//  import PageDetails from './_pages/PageDetails';
//  import UpdatePage from './_pages/UpdatePage';

const SEO = Loadable(lazy(() => import('./SEO')));
const CreatePage = Loadable(lazy(() => import('./_pages/CreateRecord')));
const PageDetails = Loadable(lazy(() => import('./_pages/PageDetails')));
const UpdatePage = Loadable(lazy(() => import('./_pages/UpdatePage')));

export const seoRoutes = [
  { path: '/seo', element: <SEO /> },
  { path: '/seo/create', element: <CreatePage /> },
  { path: '/seo/details/:id', element: <PageDetails /> },
  { path: '/seo/edit/:id', element: <UpdatePage /> },
];
