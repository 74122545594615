import Loadable from 'components/Loadable';
import { lazy } from 'react';

const LandingPages = Loadable(lazy(()=> import('./LandingPages')))
const LadingPageDetails = Loadable(lazy(() => import('./LandingPageDetail')));
const CreateLandingPage = Loadable(lazy(() => import('./CreateLandingPage')));
const EditLandingPage = Loadable(lazy(() => import('./EditLandingPage')));

export const landingPagesRoutes = [
    { path: '/landing-pages', element: <LandingPages /> },
    { path: '/landing-pages/:id', element: <LadingPageDetails /> },
    { path: '/landing-pages/edit/:id', element: <EditLandingPage /> },
    { path: '/landing-pages/create', element: <CreateLandingPage /> },
];