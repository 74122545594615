import Grid from '@mui/material/Grid';

//  import { useState } from 'react';

const Models = () => {
  return (
    <Grid container>
      <Grid> Models page </Grid>
    </Grid>
  );
};

export default Models;
