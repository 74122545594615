import { Suspense } from 'react';

// import { useLocation } from 'react-router-dom';

//  import LoadingScreen from './LoadingScreen';
import LoadingScreen from './loading-screen';

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const { pathname } = useLocation();
  
    return (
      // isDashboard={!pathname.includes('/dashboard')}
      <Suspense fallback={<LoaderComponent />}>
        <Component {...props} />
      </Suspense>
    );
};


function LoaderComponent () {

  return (
    <div sx={{ isolation: 'isolate', zIndex: (theme) => theme.zIndex.drawer - 5 }}> 
      <LoadingScreen  /> 
    </div>
  )
}

export default Loadable