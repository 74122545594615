export const renderContent = (content) => {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export const makeUrl = (str) => {
  return str.trim().toLocaleLowerCase().replace(/[^a-zA-Z ]/g, '').split(' ').filter(s => s != '').map((v) => v?.trim()).join('-')
}

export const formatCurrency = (num) => {
  const f = new Intl.NumberFormat('en-us', { currency: 'KES', style: 'currency' })

  return f.format(num)
}


export function addLeadingZeros({num, length}) {
  return String(num).padStart(length, '0');
}