import * as React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';



import { Dialog, DialogContent, TextField, DialogActions, Button, AppBar, Toolbar, IconButton } from '@mui/material'

import Page from 'components/Page'
import LoadingScreen from 'components/loading-screen';

import { useState } from 'react'

import Slide from '@mui/material/Slide';

import { toast } from 'react-toastify';

import { useDiscountCodes } from 'hooks/discount/useDiscountCodes'
import { apiURL } from 'common/index';
import http from 'common/http';

import DiscountTable from './_components/DiscountsTable';
import { useTheme } from 'styled-components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const DiscountCodes = () => {

    const theme = useTheme();
    
    const [createDialog, setCreateDialog] = useState(false)
    const [percentage, setPercentage] = useState(false)
    const [discountCode, setDiscountCode] = useState('')
    const [title, setTitle] = useState('')
    const [amount, setAmount] = useState(0)
    const [minOrder, setMinOrder] = useState(0)
    const [loader, setLoading] = useState(false)
    const [endDate, setEndDate] = useState(new Date())

    const { codes, loading, msg, error, mutate } = useDiscountCodes();

    

    const closeCreateDialog = () => {
        setPercentage(false);
        setAmount(0);
        setDiscountCode('');
        setCreateDialog(false);
        setEndDate(new Date());
        setTitle('');
        setMinOrder(0)
    }

    const handleAddCode = () => {}

    const navigateToCreate = () => {
        setCreateDialog(true)
    }

    const handleSave = async () => {
       try {
        if (!discountCode) {
            throw new Error('discount code is required')
        }
        
        if (!amount) {
            throw new Error('Amount / percentage is  required')
        }
        const url = `${apiURL}/admin/discount`
        const postData = {
            percentage,
            code: discountCode,
            value: amount,
            title,
            endDate,
            min: minOrder
        }  

        setLoading(true);

        const { data } = await http.post(url, postData);

        if (data.state) {
            closeCreateDialog();
            mutate();
            toast.success('Discount code added');
        } else {
            throw new Error(data.msg);
        }
        
       
       } catch (err) {
         toast.error(err.message);
       } finally {
           setLoading(false);
       }


    }

    if (loading) {
        return (
            <div sx={{ isolation: 'isolated' }}>
                <LoadingScreen />
            </div>
        )
    }

    return (
        <Page title="Discount codes">
            <Container >
                <Box sx={{ pb: 5 }}>
                    <Typography sx={{ color: theme?.palette?.primary?.main }} variant="h4">
                        Discount codes
                    </Typography>
                </Box>

                <Grid container>
                    <Grid item xs={12}>
                        {loading && <p>Loading discount codes...</p>}
                        {error && <p>Error loading discount codes: {msg}</p>}
                        {!loading && !error && codes && <DiscountTable mutate={mutate} codes={codes} />}
                    </Grid>
                </Grid>

                <Fab
                    onClick={navigateToCreate}
                    className="page-fab"
                    color="primary"
                    aria-label="add"
                    >
                    <AddIcon />
                </Fab>


                <Dialog fullScreen TransitionComponent={Transition} open={createDialog} onClose={closeCreateDialog}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeCreateDialog}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add discount code
                        </Typography>
                        {/* <Button autoFocus color="inherit" onClick={closeCreateDialog}>
                        save
                        </Button> */}
                    </Toolbar>
                </AppBar>
                    {/* <DialogTitle>Add discount code</DialogTitle> */}
                    <DialogContent>
                    {/* <DialogContentText>
                        Create discount code
                    </DialogContentText> */}
                    <Container>
                       
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>


                                    <DesktopDatePicker
                                        label="Expiry date"
                                        value={endDate}
                                        minDate={new Date()}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                autoFocus
                                margin="dense"
                                id="code"
                                label="Discount code"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(e) => setDiscountCode(e.target.value)}
                                value= {discountCode}
                            />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    id="amount"
                                    label="Amount / Percentage"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setAmount(e.target.value)}
                                    value= {amount}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    id="amount"
                                    label="Minimum order"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setMinOrder(e.target.value)}
                                    value= {minOrder}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    id="desc"
                                    label="Description"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value= {title}
                                />
                            </Grid>

                            
                            <Grid item xs={12}>
                                 <FormGroup>

                                    <FormControlLabel  label="Percentage" control={
                                        <Checkbox
                                        { ...{ inputProps: { 'aria-label': 'Percentage' } } }
                                        onChange={(e) => setPercentage(!percentage)}
                                        checked={percentage}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    />
                                    }></FormControlLabel>
                                </FormGroup>
                            </Grid>

                            <Grid container item direction="row" justifyContent="end">
                                <LoadingButton loading={loader} disabled={loader} onClick={handleSave} variant="contained" disableElevation> Save code</LoadingButton>
                            </Grid>

                           
                        </Grid>
                    </Container>
                    </DialogContent>
                    {/* <DialogActions>
                    <Button onClick={closeCreateDialog}>Cancel</Button>
                    <Button onClick={handleAddCode}>Add Code</Button>
                    </DialogActions> */}
                </Dialog>

            </Container>
        </Page>
        
    )
}

export default DiscountCodes