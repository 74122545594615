import { useState, createContext, useEffect, useContext } from 'react';

import http from 'common/http';

//  import { useDataInit } from 'hooks/data/useDataInit'

import { AuthContext } from './JWTContext'

export const DataContext = createContext();

export const DataContextProvider = (props) => {
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [countries, setCountries] = useState([])
  const [flavors, setFlavors] = useState([])
  const [locations, setlocations] = useState([])
  const [features, setFeatures] = useState([])

  const { isAuthenticated } = useContext(AuthContext)

  

  const initData = async () => {
    if (!isAuthenticated) return
    const { data } = await http.get('/api/data/init');
    if (data.state) {
      setColors(data.colors)
      setSizes(data.sizes)
      setCountries(data.countries)
      setFlavors(data.flavors)
      setlocations(data.locations)
      setFeatures(data.features)
    }
  };

  useEffect(() => {
      
    initData();
  }, [ isAuthenticated ]);


  return (
    <DataContext.Provider
      value={{ colors, sizes, countries, flavors, locations, features }}
    >
      {props.children}
    </DataContext.Provider>
  );
};