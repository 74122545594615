import { Grid, TextField, Card } from '@mui/material';
import { apiURL } from 'common';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useEffect } from 'react';

import http from 'common/http'

let timeout;
const ProductSearch = ({ select, currentProduct, doReset, hasError }) => {
    const [searching, setSearching] = useState(false);
    const [products, setProducts] = useState([]);
    const [active, setActive] = useState(null);
    const [searchPhrase, setSearchPhrase] = useState('');
    const [touched, setTouched] = useState(false)


    useEffect(() => {
        // set products and searchPhrase
        if (currentProduct) {
            setProducts([currentProduct]);
            setSearchPhrase(currentProduct.name);
        }
    }, [ currentProduct ])
    
    const handleSearch = async (e) => {
        setTouched(true)
        try {
            const keyword = e.target.value;
            setSearchPhrase(keyword);
           
            clearTimeout(timeout);
            timeout = setTimeout( async () => {
                setActive(null);
                if (keyword === '' || keyword === active?.name) {
                    return;
                }
                const url = `${apiURL}/admin/product/search/${keyword}`
                setSearching(true);
                const { data } = await http.get(url);
                setSearching(false);
                if (data.state) {
                    setProducts(data.products)
                }
            }, 300);
        } catch (err) {
            toast.error(err.message);
        } finally {
            setSearching(false);
        }
    }

    const handleSelection = (product) => {
        if (!product) return;
        setActive(product);
        select(JSON.parse(JSON.stringify(product)));
        setProducts([]);
        setSearchPhrase(product.name)

        if (doReset) {
            reset()
        }
    }
    const reset = () => {
        setProducts([])
        setSearchPhrase('')
        setActive(null);
    }

    return (
        <>
            <Grid sx={{ my: 2}} container>
               <Grid item xs={12} sx={{ position: 'relative'}}>
                <TextField
                        margin="dense"
                        error={hasError && touched}
                        id="search"
                        type="text"
                        name="search"
                        fullWidth
                        onChange={handleSearch}
                        label="Search product"
                        value={searchPhrase}
                        autoComplete='off'
                    />
                    <Card sx={{ p: 3 }} elevation={2} className={`search-suggestions ${searching || products.length >  0 ? '' : 'hidden' }`}>
                        {searching && <p>Searching...</p>}
                        {products.length >= 1 && !searching && !active && touched && (<div>
                            {products.map((product) => {
                                return (<div key={product._id} className="search-item" onClick={() => handleSelection(product)}>{product.name}</div>)
                            })}
                        </div>)}
                    </Card>
               </Grid>
            </Grid>
        </>
    )
}

export default ProductSearch;