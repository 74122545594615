import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Page from 'components/Page';
import useSettings from 'hooks/useSettings';

// sections
import UserNewForm from 'sections/user/UserNewForm';

const PREFIX = 'CreateUserPage';


const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  [`& .${classes.fixedHeight}`]: {
    height: 240,
  },
}));



export const CreateUser = () => {
  const { themeStretch } = useSettings();

  return (
    <Page title="Add user">
      <Container  maxWidth={themeStretch ? false : 'lg'}>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">New User</Typography>
        </Box>
        <Grid container>
          <UserNewForm />
        </Grid>
      </Container>
    </Page>
  );
};
export default CreateUser;
