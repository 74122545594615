import { useState, createContext, useEffect, useCallback, useContext } from 'react';

import http from 'common/http';
import { AuthContext } from './JWTContext'

export const BrandContext = createContext();

export const BrandContextProvider = (props) => {
  const [brands, setBrands] = useState([]);

  const { isAuthenticated } = useContext(AuthContext)

  const initializeBrands = async () => {
    if (!isAuthenticated) {
      //  console.log('not authenticated, cant fetch brands', isAuthenticated);
      setBrands([])
      return
    }
    const { data } = await http.get('/admin/brand');
    if (data.state) {
      const _brands = data.brands.map((brand) => {
        return { name: brand.name, url: brand.url, id: brand.id }
      })
      setBrands(_brands);

    }
  };

  const addBrand = useCallback((brand) => {
    setBrands([...brands, brand]);
  }, []);

  useEffect(() => {
    initializeBrands();
  }, [ isAuthenticated]);

  const loadBrands = (brands) => {
    setBrands(brands);
  };

  const deleteBrand = useCallback( (brand) => {
    setBrands(brands.filter((b) => b.id === brand.id));
  }, []);

  return (
    <BrandContext.Provider
      value={{ brands, loadBrands, deleteBrand, addBrand }}
    >
      {props.children}
    </BrandContext.Provider>
  );
};
