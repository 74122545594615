import { useContext, useState } from 'react'

import Grid from '@mui/material/Grid'
//  import Container from '@mui/material/Container';
// import Checkbox from '@mui/material/Checkbox'
// import FormGroup from '@mui/material/FormGroup';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';

import HTMLEditor from 'components/shared/Editor';
import Editor from 'components/editor';
import { toast } from 'react-toastify';
import { BrandContext } from 'context/BrandContext';
import { CategoryContext } from 'context/CategoryContext';

const PageEditor = ({ handleSave, initial }) => {
    const { brands } = useContext(BrandContext)
    const { categories  } = useContext(CategoryContext)

    const [page, setPage] = useState(initial.name || '');
    const [url, setUrl] = useState(initial.url || '');
    const [title, setTitle] = useState(initial.metaTitle || '');
    const [content, setContent] = useState(initial?.content || '');
    const [description, setDescription] = useState(initial.metaDescription || '');
    const [summary, setSummary] = useState(initial.summary || '');
    const [loader, setLoader] = useState(false);
    const [brandId, setBrandId] = useState(initial?.brand?.id ?? '')
    const [categoryId, setCategoryId] = useState(initial?.category?.id ?? '')

    const save = async () => {
        try {
            if (loader) return false
            if (!page) throw(new Error('Page name is required'))
            if (!url) throw(new Error('Page url is required'))
            if (!title) throw(new Error('Page title is required'))
            if (!description) throw(new Error('Meta description required'));

            const pageData = {
                name: page,
                url,
                metaTitle: title,
                content,
                metaDescription: description,
                summary,
                brandId: brandId || undefined,
                categoryId: categoryId || undefined
            }
            setLoader(true)
           await handleSave(pageData, setLoader)
           
        } catch (err) {
            toast.error(err.message)
        } finally {
            setLoader(false)
        }
    }

    return (
        <> 

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    margin="dense"
                    id="code"
                    label="Page name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setPage(e.target.value)}
                    autoComplete='off'
                    value= {page}
                />
                </Grid>

                <Grid item xs={12} sm={6} >
                    <TextField
                    margin="dense"
                    id="code"
                    label="Url"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setUrl(e.target.value)}
                    autoComplete='off'
                    value= {url}
                />
                </Grid>

                <Grid item xs={12} sm={6} >

                <FormControl  fullWidth>
                    <InputLabel id="brand-label">Brand</InputLabel>
                    <Select
                        labelId="brand-label"
                        variant="standard"
                        id="brand-select"
                        value={brandId}
                        label="Brand"
                        onChange={(e) => {
                            // console.log(e.target.value)
                            setBrandId(e.target.value)
                        }}
                    >
                        {
                            brands?.map((brand) => {
                                return <MenuItem key={brand.id} value={brand.id}> {brand.name} </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

               
                    
                </Grid>

                <Grid item xs={12}  sm={6}>
                <FormControl  fullWidth>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                        labelId="category-label"
                        variant="standard"
                        id="category-select"
                        value={categoryId}
                        label="Category"
                        onChange={(e) => {
                            // console.log(e.target.value)
                            setCategoryId(e.target.value)
                        }}
                    >
                        {
                            categories?.map((brand) => {
                                return <MenuItem key={brand.id} value={brand.id}> {brand.name} </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                    margin="dense"
                    id="code"
                    label="Meta title"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete='off'
                    value= {title}
                />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                    margin="dense"
                    id="metaDesc"
                    label="Meta Description"
                    type="text"
                    variant="standard"
                    fullWidth
                    onChange={(e) => setDescription(e.target.value)}
                    minRows={2}
                    rows={2}
                    autoComplete='off'
                    value= {description}
                />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                    margin="dense"
                    id="summary"
                    label="Page summary"
                    type="text"
                    variant="standard"
                    fullWidth
                    onChange={(e) => setSummary(e.target.value)}
                    minRows={2}
                    rows={2}
                    autoComplete='off'
                    value= {summary}
                />
                </Grid>


                <Grid item xs={12}>
                    {/* <HTMLEditor setContent={setContent} initial={''} /> */}
                    <Editor
                         id="desc-content"
                         value={content}
                         onChange={(val) => setContent(val)}
                         helperText=""
                         placeholder="Page description"
                    />
                </Grid>


                <Grid container item direction="row" xs={12} justifyContent="end" alignItems="end">
                    <LoadingButton loading={loader} onClick={save} variant="contained" disableElevation > Submit </LoadingButton>
                </Grid>
            </Grid>
            
        </>
    );
}


export default PageEditor;

