import { lazy } from 'react';

import Users from './Users';
import CreateUser from './CreateUser';
import UserDetails from './UserDetails';
import Loadable from 'components/Loadable';

const MyAccount = Loadable(lazy(() => import('./MyAccount')));

export const usersRoutes = [
  { path: '/users', element: <Users /> },
  { path: '/users/create', element: <CreateUser /> },
  { path: '/users/profile/:id', element: <UserDetails /> },
  { path: '/user/account', element: <MyAccount /> },
];
