import Loadable from 'components/Loadable';
import { lazy } from 'react';

const ShoppingGuides = Loadable(lazy(()=> import('./ShoppingGuides')));
const ShoppingGuideDetails = Loadable(lazy(() => import('./ShoppingGuideDetail')));
const CreateShoppingGuide = Loadable(lazy(() => import('./CreateShoppingGuide')));
const EditShoppingGuide = Loadable(lazy(() => import('./EditShoppingGuide')));

export const shoppingGuideRoutes = [
    { path: '/shopping-guides', element: <ShoppingGuides /> },
    { path: '/shopping-guides/details/:id', element: <ShoppingGuideDetails /> },
    { path: '/shopping-guides/edit/:id', element: <EditShoppingGuide /> },
    { path: '/shopping-guides/create', element: <CreateShoppingGuide /> },
];