/* eslint-disable quotes */
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { format } from 'date-fns';

import http from 'common/http';

export default function OrdersTable({ orders, mutate }) {
  const [loader, setLoader] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [deliveryLoader, setDeliveryLoader] = useState(false);



  const cancelOrder = async (id) => {
    try {
      setActiveId(id);
      setLoader(true);
      const { data } = await http.get(`/admin/order/cancel/${id}`);
      if (data.state) {
        mutate();
        toast.success('Order canceled successfully');
      } else {
        throw new Error('Failed');
      }
    } catch (err) {
      toast.error('Error canceling failed');
    } finally {
      setLoader(false);
      setActiveId(null);
    }
  };

  // console.log(orders)

  const markDelivered = async (id) => {
    // loaded
    try {
      setActiveId(id);
      setDeliveryLoader(true);
      const { data } = await http.get(`/admin/order/close/${id}`);
      if (data.state) {
        toast.success('Marked as delivered');
        mutate();
      } else {
        throw new Error('Error');
      }
    } catch (err) {
      toast.error('Failed to mark delivered');
    } finally {
      setDeliveryLoader(false);
      setActiveId(null);
    }
  };
  const formatCurrency = (num) => {
    const f = new Intl.NumberFormat("en-us", { currency: 'KES', style: 'currency' })

    return f.format(num)
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Placed on</TableCell>
            <TableCell align="right">Delivery location</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Products</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Cancel</TableCell>
            <TableCell align="right">Delivered</TableCell>
            <TableCell align="right">More</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow
              key={order._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                  {/* {order?.placedOn ?format(new Date(Number(order?.placedOn)), 'dd-MM-yyyy HH:mm') : 'N/A'}   */}
                  {new Date(order.createdAt).toDateString()}
              </TableCell>
              <TableCell align="right">{order?.delivery?.location ?? order?.location }</TableCell>
              <TableCell align="right">{formatCurrency(order?.subtotal)}</TableCell>
              <TableCell align="right">{order?.cart?.length}</TableCell>
              <TableCell align="right">
                {order?.handled ? 'Handled' : 'Pending'}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => cancelOrder(order._id)}
                  color="secondary"
                >
                  {loader && activeId === order._id ? 'Loading...' : 'Cancel'}
                </Button>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => markDelivered(order._id)}
                >
                  {loader && activeId === order._id
                    ? 'Loading...'
                    : 'Delivered'}
                </Button>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  component={Link}
                  to={`/orders/details/${order._id}`}
                  color="primary"
                  variant="contained"
                >
                  <RemoveRedEye />{' '}
                </IconButton>{' '}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
