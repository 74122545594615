import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  
export default  function ConfirmationDialog({ title, confirmationDialog, setConfirmationDialog, handleDelete }) {
    // const [open, setOpen] = React.useState(false);
  
    // const handleClickOpen = () => {
    //   setConfirmationDialog(true);
    // };
  
    const handleClose = () => {
      setConfirmationDialog(false);
    };
  
    return (
        <Dialog
          open={confirmationDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{'Confirm item deletion'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              { title ?? 'Do you want to delete this user?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button  onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
    );
  }