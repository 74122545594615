import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Delete from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { apiURL } from 'common';
import http from 'common/http';

const DiscountsTable = ({ codes, mutate }) => {

    const handleDelete = async (id) => {
        try {
            const url = `${apiURL}/admin/discount/${id}`;

            const { data } = await http.delete(url);

            if (data.state) {
                mutate()
            } else {
                throw new Error(data.msg)
            }

        } catch (err) {
            toast.error(err.message);
        }
    }
    return (
        <>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Discount title</TableCell>
                        <TableCell>Discount code</TableCell>
                        <TableCell> Value / Amount </TableCell>
                        <TableCell align="right">Is percentage</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">End date</TableCell>
                        <TableCell align="right">Delete</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {codes.map((code) => (
                            <TableRow
                            key={code._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{code.title} </TableCell>
                                <TableCell align="right">{code.code}</TableCell>
                                <TableCell align="right">{code.value}</TableCell>
                                <TableCell align="right">{code.percentage ? 'Yes' : 'No' }</TableCell>
                                <TableCell align="right">{code.active ? <p>Active</p> : <p>Expired</p> }</TableCell>
                                <TableCell align="right">{code.endDate &&  format(new Date(code.endDate), 'dd-MM-yyyy HH:mm') }</TableCell>
                                <TableCell align="right"> <IconButton onClick={() => handleDelete(code._id)} > <Delete /> </IconButton>  </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default DiscountsTable;