import useSWR from 'swr';

import { apiURL } from 'common/index';

export const useSettings = () => {
  const url = `${apiURL}/admin/settings`;
  const { error, data, mutate } = useSWR(url);

  return {
    loading: !error && !data,
    error,
    settings: data && data.state ? data.settings : null,
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};

export default useSettings;