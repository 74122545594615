import Loadable from 'components/Loadable';
import { lazy } from 'react';


import { AppPaths } from 'routes/paths';

const FeaturedProducts = Loadable(lazy(() => import('./_pages/Featured')));
const OutOfStock = Loadable(lazy(() => import('./_pages/OutOfStock')));
const Offers = Loadable(lazy(() => import('./_pages/Offers')));
const Reviews = Loadable(lazy(() => import('./_pages/ProductReviews')));
const ProductDetails = Loadable(lazy(() => import('./_pages/ProductDetails')));
const EditProduct = Loadable(lazy(() => import('./_pages/EditProduct')));
const CreateProduct = Loadable(lazy(() => import('./_pages/NewProduct')));
const Products = Loadable(lazy(() => import('./Products')));



const _paths = AppPaths.general.products;

export const productRoutes = [
  { path: '/products', element: <Products /> },
  { path: '/products/list', element: <Products /> },
  { path: '/products/create', element: <CreateProduct /> },
  { path: '/products/details/:id', element: <ProductDetails /> },
  { path: '/products/edit/:id', element: <EditProduct /> },
  { path: _paths.featured, element: <FeaturedProducts /> },
  { path: _paths.offers, element: <Offers /> },
  { path: _paths.outOfStock, element: <OutOfStock /> },
  { path: _paths.reviews, element: <Reviews /> },
];
