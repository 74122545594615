//  import { ThemeProvider } from '@mui/styles';
// import { useState, useEffect, useContext } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { SWRConfig } from 'swr';

//  import { RecoilRoot } from 'recoil';

import './assets/App.css';

import { AppRoutes } from './routes';

import { FlavorContextProvider } from 'context/FlavorContext';
import { BrandContextProvider } from 'context/BrandContext';
import { FeatureContextProvider } from 'context/FeatureContext';
import { CategoryContextProvider } from 'context/CategoryContext';
import { CountryContextProvider } from 'context/CountryContext';
// import { UserContextProvider } from 'context/UserContext';

import ThemeProvider from './theme';

// import http from 'common/http';

// import { UserContext } from 'context/UserContext';

import ThemeColorPresets from 'components/ThemeColorPresets';
import ThemeLocalization from 'components/ThemeLocalization';
import Settings from 'components/settings';
import RtlLayout from 'components/RtlLayout';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import ScrollToTop from 'components/ScrollToTop';
import { ProgressBarStyle } from 'components/ProgressBar';
import { StyledChart } from 'components/chart';


const fetcher = (...args) => fetch(...args).then((res) => res.json()).catch((err) => ({ error: err.message }) );
// const ctxWrapper = ({ children }) => {
//   return ();
// }

function App() {
 
  return (
    // <UserContextProvider>
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <MotionLazyContainer>
              <CategoryContextProvider>
                <BrandContextProvider>
                  <FlavorContextProvider>
                    <FeatureContextProvider>
                        <CountryContextProvider>
                          <SWRConfig suspense={true} value={{ fetcher }}>
                            {/* <StyledEngineProvider injectFirst> */}
                                <StyledChart />
                                <ToastContainer hideProgressBar={true} />
                                {/* <ThemeProvider theme={theme}> */}
                                {/* <CssBaseline /> */}
                                <Settings />
                                <ScrollToTop />
                                <ProgressBarStyle />
                                <AppRoutes />
                                {/* </ThemeProvider> */}
                            
                              
                            {/* </StyledEngineProvider> */}
                          </SWRConfig>
                        </CountryContextProvider>
                    </FeatureContextProvider>
                  </FlavorContextProvider>
                </BrandContextProvider>
              </CategoryContextProvider>
          </MotionLazyContainer>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}

export default App;

//    git config user.email "wach.wachira7@gmail.com"