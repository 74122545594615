import Loadable from 'components/Loadable';
import { lazy } from 'react';

import { AppPaths } from 'routes/paths';

const Testimonials = Loadable(lazy(()=> import('./Testimonials')));
const CreateTestmonial = Loadable(lazy(() => import('./_pages/CreateTestmonial')));
const EditTestmonial = Loadable(lazy(() => import('./_pages/EditTestmonial')));
const TestmonialDetail = Loadable(lazy(() => import('./_pages/TestmonialDetail')))



const _paths = AppPaths.marketing.testimonials


export const testmonialsRoutes = [
    { path: _paths.list, element: <Testimonials /> },
    { path: _paths.create, element: <CreateTestmonial /> },
    { path: _paths.edit, element: <EditTestmonial /> },
    { path: _paths.details, element: <TestmonialDetail /> }
];