import axios from 'axios';

import { apiURL } from 'common';

export const host = apiURL;
// export const host = "";

export const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: host,
  timeout: 20000,
  // headers: {
  //   'x-access-token': token,
  //   'Access-Control-Allow-Origin': '*',
  //     mode: 'no-cors',
  // },
});

export default axiosInstance