import Grid from '@mui/material/Grid';

//  import { useState } from 'react';

const CreateModel = () => {
  return (
    <Grid container>
      <Grid> Create modle </Grid>
    </Grid>
  );
};

export default CreateModel;
