import Loadable from 'components/Loadable';
import { lazy } from 'react';

const Blog = Loadable(lazy(() => import('./Blog')));
const ArticleDetails = Loadable(lazy(() => import('./_pages/ArticleDetails')));
const CreateArticle = Loadable(lazy(() => import('./_pages/CreateArticle')));
const UpdateArticle = Loadable(lazy(() => import('./_pages/UpdateArticle')));


export const blogRoutes = [
  { path: '/blog', element: <Blog /> },
  { path: '/blog/details/:id', element: <ArticleDetails /> },
  { path: '/blog/create', element: <CreateArticle /> },
  { path: '/blog/update/:id', element: <UpdateArticle /> },
];
