import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

// import { styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Page from 'components/Page';

import LoadingScreen from 'components/loading-screen';

import OrdersTable from './_components/OrdersTable';

import { useOrders } from 'hooks/orders';
import { useTheme } from 'styled-components';

//  const PREFIX = 'OrdersPage';

export const OrdersPage = () => {
  const { loading, orders, error, mutate } = useOrders();
  const theme = useTheme()

  if (loading) {
    return <div sx={{ isolation: 'isolated' }}> <LoadingScreen /> </div>;
  }
  if (error || !orders) {
    console.log(error, orders);
    return <div>Error loading data </div>;
  }

  // console.log(orders, 'orders')

  return (
    <Page title="Customer orders">
      <ErrorBoundary fallback={<div> Error loading order data </div>} >
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
              Customer orders
            </Typography>
          </Box>
          <Grid container>
            <ErrorBoundary fallback={<div> Invalid order data </div>} >
              <OrdersTable mutate={mutate} orders={orders} />
            </ErrorBoundary>
          </Grid>
        </Container>
      </ErrorBoundary>
    </Page>
  );
};
