import { useState, forwardRef } from 'react'

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { AppBar, Toolbar, IconButton, DialogContent, Container, Grid, Button, TextField, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import { LoadingButton } from '@mui/lab';

//  import PageSearch from 'components/shared/PageSearch';
import ProductSearch from 'components/shared/ProductSearch';

import { toast } from 'react-toastify';

import { apiURL } from 'common/index'
import http from 'common/http'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddProductToCustomPage = ({ mutate, pageId, page }) => {
    

    const [ product, setProduct] = useState(null)
    const [ open, setOpen] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const handleClose = () => {
        setOpen(false);
    }

    const handleSelection = (product) => {
        //  console.log(page)
        setProduct(product);
       
    }

    const handleSave = async () => {
        try {
            if (!product) throw(new Error('Select page'))

            const exists = product.customPages.find((p) => p.pageId === pageId)

            if (exists) throw(new Error('Page already added'));
            const url = `${apiURL}/admin/product/add-custom-page/${product._id}`;
            setLoading(true)
            const { data } = await http.put(url, { pageId, url: page.url })
            if (data.state) {
                handleClose();
                mutate()
                toast.success('Added to page')
            } else {
                throw new Error(data.msg);
            }
        } catch (err) {
            toast.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    //  render fn
    return (
        <>
            <Button variant="contained" color='info' onClick={() => setOpen(true)} disableElevation> Add Product</Button>
            <Dialog fullScreen TransitionComponent={Transition} open={open} onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }} color="info">
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                           Add to new cutom page
                        </Typography>
                        
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Container>
                        Dialog content

                       <Grid container spacing={3}>
                           <Grid item xs={12}>
                                <ProductSearch select={handleSelection} />
                           </Grid>

                           <Grid item container xs={12} direction="row" justifyContent="end">
                               <LoadingButton color="info" loading={loading} onClick={handleSave} variant="contained" disableElevation >Add to page</LoadingButton>
                           </Grid>
                       </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddProductToCustomPage;