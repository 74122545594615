import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid';
import Typography  from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';

import { useNavigate } from 'react-router-dom';

import Page from 'components/Page';
import LoadingScreen from 'components/loading-screen'
import CustomPagesTable from './_components/CustomPagesTable';

import { usePages } from 'hooks/pages/usePages';

const CustomPages = () => {
    const navigate = useNavigate();
    const navigateToCreate = () =>  navigate('/custom-pages/create');

    const { pages, loading, error, msg, mutate } = usePages()


    if (loading) {
        return (
            <div sx={{ isolation: 'isolated' }}>
                <LoadingScreen />
            </div>
        )
    }

    return(
    <Page title="Custom pages">
        <Container>
            <Typography variant="h4" className="primary_color capitalize-first">
               Custom pages
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    <RenderPages pages={pages} loading={loading} error={error} msg={msg} mutate={mutate} />
                </Grid>
            </Grid>
            <Fab
            onClick={navigateToCreate}
            color="primary"
            aria-label="add"
            className="page-fab"
            >
                <AddIcon />
            </Fab>
        </Container>
        
    </Page>
    )    
}

const RenderPages = ({ pages, loading, error, msg, mutate }) => {
    
    if (loading) {
        return (
            <div>Loading...</div>
        )
    }

    if (error) {
        return (
            <div>
                <h3>Error loading page data</h3>
                <p>{msg}</p>
            </div>
        )
    }

    return (
        <div>
           <CustomPagesTable pages={pages} mutate={mutate} />
        </div>
    )
}

export default CustomPages;