import Loadable from 'components/Loadable';
import { lazy } from 'react';

const Categories = Loadable(lazy(() => import('./Categories')));
const CreateCategory = Loadable(lazy(() => import('./_pages/CreateCategory')));
const CategoryDetails = Loadable(lazy(() => import('./_pages/Details')));
const UpdateCategory = Loadable(lazy(() => import('./_pages/UpdateCategory')));

const SubcategoryDetail = Loadable(lazy(() => import('./_pages/SubcategoryDetail')));
const UpdateSubcategory = Loadable(lazy(() => import('./_pages/UpdateSubcategory')));

export const categoriesRoutes = [
  { path: '/categories', element: <Categories /> },
  { path: '/categories/create', element: <CreateCategory /> },
  { path: '/categories/details/:categoryId', element: <CategoryDetails /> },
  { path: '/categories/update/:id', element: <UpdateCategory /> },

  { path: '/categories/sub/detail/:id', element: <SubcategoryDetail /> },

  { path: '/categories/sub/update/:id', element: <UpdateSubcategory /> },
];
