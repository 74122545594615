import useSWR from 'swr';

import { apiURL } from 'common/index';

export * from './orderDetails'

export const useOrders = () => {
  const url = `${apiURL}/admin/order/active`;
  const { error, data, mutate } = useSWR(url);

  return {
    loading: !error && !data,
    error,
    orders: data && data.state ? data.orders : [],
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};

export default useOrders;
