import useSWR from 'swr';

import { apiURL } from 'common/index';

export const usePageDetail = (id) => {
  const url = `${apiURL}/admin/page/${id}`;
  const { error, data, mutate } = useSWR(url);

  return {
    loading: !error && !data,
    error,
    page: data && data.state ? data.page : null,
    products: data && data.state ? data.products : [],
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};

export default usePageDetail;