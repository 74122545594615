import useSWR from 'swr';

import { apiURL } from 'common/index';

export const useDiscountCodes = () => {
  const url = `${apiURL}/admin/discount`;
  const { error, data, mutate } = useSWR(url);

  return {
    loading: !error && !data,
    error,
    codes: data && data.state ? data.codes : [],
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};

export default useDiscountCodes;