import useSWR from 'swr';

import { apiURL } from 'common/index';

export const useOrderDetails = (id) => {
  const url = `${apiURL}/admin/order/details/${id}`;
  const { error, data, mutate } = useSWR(url);

  return {
    loading: !error && !data,
    error,
    order: data && data.state ? data.order : {},
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};

export default useOrderDetails;