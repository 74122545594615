import useSWR from 'swr';

import { apiURL } from 'common/index';

const useUsers = () => {
  const url = `${apiURL}/admin/user`;
  const { error, data, mutate } = useSWR(url);

  return {
    loading: !error && !data,
    error,
    users: data && data.state ? data.users : [],
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};

export default useUsers;
