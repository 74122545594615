import { useState, createContext, useEffect } from 'react';

import http from 'common/http';

export const UserContext = createContext({});

export const UserContextProvider = (props) => {
  const [user, setUser] = useState({});
  const [authenticated, setAuth] = useState(false);

  //   const initializeUser = async () => {
  //     const token = localStorage.getItem('token');
  //     if (!token) {
  //       return;
  //     }
  //     // decode token
  //   };

  //   useEffect(() => {
  //     initializeUser();
  //   }, []);

  const loadUser = (user) => {
    setUser(user);
  };

  const setAuthStatus = (val) => {
    setAuth(val);
  };

  return (
    <UserContext.Provider
      value={{ user, loadUser, authenticated, setAuthStatus: setAuthStatus }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
