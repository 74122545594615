import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel } from '@mui/material';
// utils
import { fData } from 'utils/formatNumber';
// routes
import { PATH_APP } from 'routes/paths';
// _mock

// components
import Label from 'components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import { imageHost, userRoles } from 'common';
import { toast } from 'react-toastify';

import http from 'common/http'


// ----------------------------------------------------------------------

UserNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function UserNewForm({ isEdit, currentUser }) {
  const navigate = useNavigate();

  const passwordFields = isEdit ? {} : {
    password: Yup.string().required('Password is required'),
    password1: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match').required('Passord is required')
  }

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email(),
    phoneNumber: Yup.string().required('Phone number is required'),
    role: Yup.string().required('Role Number is required'),
    avatarUrl: Yup.mixed().test('required', 'Avatar is required', (value) => value !== ''),
    ...passwordFields
   
    
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      email: currentUser?.email || '',
      phoneNumber: currentUser?.phoneNumber || '',
      avatarUrl: currentUser?.avatarUrl ? `${imageHost}/users/${currentUser?.avatarUrl}`  : '',
      isVerified: currentUser?.isVerified || false,
      status: currentUser?.status || false,
      role: currentUser?.role || '',
      password: '',
      password1: ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async () => {
    try {
      
      return isEdit ? await updateUser() : await saveUser()
    } catch (error) {
      toast.error('Operation failed: ' + error.message)
      console.error(error);
    }
  };

  const saveUser = async () => {
    try {
      const values = getValues()

      const userProfile = values.avatarUrl
      console.log(values)
      
      const formData = new FormData()

      formData.append('image', userProfile)

      const userData = JSON.parse(JSON.stringify(values))
      delete userData.avatarUrl

      formData.append('user', JSON.stringify(userData))

      const url = '/api/auth/create-user'
      const { data } = await http.post(url, formData)
      if (data.state) {
        const url = PATH_APP.user.profile.replace(':id', data.user._id)
        navigate(url)
      } else {
        toast.error(data.msg)
      }
      
    } catch (error) {
      toast.error('Operation failed: ' + error.message)
    }
  }

  const updateUser = async () => {
    try {
      const values = getValues()
      delete values.password


      const userProfile = values.avatarUrl
      
      const formData = new FormData()

      formData.append('image', userProfile)

      const userData = JSON.parse(JSON.stringify(values))
      delete userData.avatarUrl

      formData.append('user', JSON.stringify(userData))

      const url = '/api/auth/update-user/' + currentUser._id
      const { data } = await http.post(url, formData)
      if (data.state) {
        const url = PATH_APP.user.profile.replace(':id', data.user._id)
        navigate(url)
      } else {
        toast.error(data.msg)
      }
    } catch (error) {
      toast.error('Operation failed: ' + error.message)
    }
  }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            {isEdit && (
              <Label
                color={values?.status !== 'active' ? 'error' : 'success'}
                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
              >
                <span>{values?.status}</span>
              </Label>
            )}

            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                accept="image/*"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

            {isEdit && (
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value !== 'active'}
                        onChange={(event) => field.onChange(event.target.checked ? 'banned' : 'active')}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Banned
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Apply disable account
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            )}

            <RHFSwitch
              name="isVerified"
              labelPlacement="start"
              
              label={
                <>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Email Verified
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Disabling this will automatically send the user a verification email
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="name" label="Full Name" />
              <RHFTextField name="email" label="Email Address" />
              <RHFTextField name="phoneNumber" label="Phone Number" />

              <RHFSelect name="role" label="Role" placeholder="User role">
                <option value="" />
                {userRoles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </RHFSelect>

             {!isEdit ? (
               <>
                <RHFTextField name="password" label="Password" type="password" />
                <RHFTextField name="password1" label="Confirm password" type="password" />
               </>
             ): null }
              
              {/* <RHFTextField name="role" label="Role" /> */}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create User' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
