// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

import 'react-image-lightbox/style.css';

// slick-carousel
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';


import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

import { BrowserRouter as Router } from 'react-router-dom';
import { UserContextProvider } from 'context/UserContext';
import { AuthProvider } from 'context/JWTContext'
import { DataContextProvider } from 'context/DataContext'
import { SettingsProvider } from 'context/SettingsContext';
import { CollapseDrawerProvider } from 'context/CollapseDrawerContext';

// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


// editor
import 'react-quill/dist/quill.snow.css';

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container);



root.render(
  <AuthProvider>
    <HelmetProvider>
     
        {/* <React.StrictMode> */}
          <DataContextProvider>
            <UserContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <Router>
                    <App />
                  </Router>
                </CollapseDrawerProvider>
              </SettingsProvider>
              </LocalizationProvider>
            </UserContextProvider>
          </DataContextProvider>
      
        {/* </React.StrictMode> */}
     
    </HelmetProvider>
  </AuthProvider>
  //  document.getElementById('root')
);
