import useSWR from 'swr';

import { apiURL } from 'common/index';

export const useUserDetails = (id) => {
  const url = `${apiURL}/admin/user/details/${id}`;
  const { error, data, mutate } = useSWR(url, {suspense: true });

  return {
    loading: !error && !data,
    error,
    user: data && data.state ? data.user : null,
    msg: data && !data.state ? data.msg : '',
    mutate,
  };
};


