import { useState, createContext, useEffect } from 'react';

import http from 'common/http';

export const FlavorContext = createContext();

export const FlavorContextProvider = (props) => {
  const [flavors, setFlavors] = useState([]);

  const initializeFlavors = async () => {
    const { data } = await http.get('/admin/flavor');
    if (data.state) {
      setFlavors(data.flavors);
    }
  };

  const addFlavor = (flavor) => {
    setFlavors([...flavors, flavor]);
  };

  useEffect(() => {
    initializeFlavors();
  }, []);

  const loadFlavors = (flavors) => {
    setFlavors(flavors);
  };

  const deleteFlavor = (flavor) => {
    setFlavors(flavors.filter((b) => b._id === flavor._id));
  };

  return (
    <FlavorContext.Provider
      value={{ flavors, loadFlavors, deleteFlavor, addFlavor }}
    >
      {props.children}
    </FlavorContext.Provider>
  );
};
