import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Page from 'components/Page';

import UserNewForm from 'sections/user/UserNewForm';
import { useUserDetails } from 'hooks/user';
import { SkeletonProductItem } from 'components/skeleton';





export const UserDetails = () => {
  const { id } = useParams()
  const { user, mutate } = useUserDetails(id)
  return (
    <Page title="My account">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">User details</Typography>
        </Box>
        <Grid container>
         <Suspense fallback={<SkeletonProductItem />}>
           <UserNewForm currentUser={user} isEdit={true} />
         </Suspense>
        </Grid>
      </Container>
    </Page>
  );
};

export default UserDetails;
