import { useState, createContext, useEffect, useCallback, useContext } from 'react';

import http from 'common/http';

import { AuthContext } from './JWTContext'

export const CategoryContext = createContext();

export const CategoryContextProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const { isAuthenticated } = useContext(AuthContext)

  const initCategories = async () => {
    //  console.log('isAuthenticated', isAuthenticated)
    if (!isAuthenticated) {
      setCategories([])
      setSubcategories([])
      return
    }
    const { data } = await http.get('/admin/category');
    //  console.log('data', data);
    
    if (data.state) {

      const _categories = data.categories.map((category) => {
        const categoryId = category._id;
        const subcategories = data.subcategories.filter((subcategory) => subcategory.categoryId === categoryId)
        return { ...category, subcategories}

      })
      setCategories(_categories);
      //  setCategories(data.categories);
      setSubcategories(data.subcategories);
    }
  };

  const deleteCategory = useCallback( (category) => {
    setCategories([...categories.filter((c) => c._id === category._id)]);
  }, [ categories ]);

  useEffect(() => {
    initCategories();
  }, [ isAuthenticated ]);

  return (
    <CategoryContext.Provider
      value={{ categories, subcategories, deleteCategory }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
