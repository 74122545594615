import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/checkmark-circle-outline';
import editIcon from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { toast } from 'react-toastify';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import ConfirmationDialog from 'components/ConfirmationDialog';

import http from 'common/http'
import { PATH_APP } from 'routes/paths';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ activateAccount, id, status, mutate }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog ] = useState(false)

  const deleteUser = async () => {
    try {

      const url = `/admin/user/delete/${id}`

      const { data } = await http.delete(url)

      if (data.state) {
        mutate()
        toast.success('User account removed')
      } {
        toast.error(data.msg)
      }


    } catch (err) {
      toast.error(`Operation error: ${err.message}`)
    }
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} size="large">
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to={PATH_APP.user.profile.replace(':id', id)}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editIcon} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            activateAccount(id);
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={`${status ? 'Deactivate' : 'Activate'}`}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={ () => { setIsOpen(false); setConfirmationDialog(true); }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        confirmationDialog={confirmationDialog} 
        setConfirmationDialog={setConfirmationDialog}
        handleDelete={deleteUser}/>
    </>
  );
}
