import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@mui/material';
import { useTheme } from 'styled-components';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = 'Plexa', ...other }, ref) => {
  const theme = useTheme()
  return (
  
    <Box ref={ref} {...other}>
      <Helmet>
        <title sx={{ color: theme.palette.primary.main }}>{title}</title>
      </Helmet>
      {children}
    </Box>
  )
}
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
