import CustomPages from './CustomPages';
import CreateCustomPage from './_pages/CreatePage';
import PageDetails from './_pages/PageDetail';
import EditPage from './_pages/EditCustomPage';

export const pagesRoutes = [
    { path: '/custom-pages', element: <CustomPages /> },
    { path: '/custom-pages/create', element: <CreateCustomPage /> },
    { path: '/custom-pages/details/:id', element: <PageDetails /> },
    { path: '/custom-pages/edit/:id', element: <EditPage /> },
];
