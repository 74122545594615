import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Page from 'components/Page';

const PREFIX = 'AccountPage';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  [`& .${classes.fixedHeight}`]: {
    height: 240,
  },
}));

export const AccountPage = () => {
  return (
    <Page title="My account">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">My Account</Typography>
        </Box>
        <Grid container>
          <Root>My account</Root>
        </Grid>
      </Container>
    </Page>
  );
};
export default AccountPage;
