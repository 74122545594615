import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Delete from '@mui/icons-material/Delete'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import { IconButton, Button } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { apiURL } from 'common';
import http from 'common/http';
import Edit from '@mui/icons-material/Edit';
import { AppPaths } from 'routes/paths';
import ConfirmDialog from 'components/confirm-dialog';
import { LoadingButton } from '@mui/lab';

const CustomPagesTable = ({ pages, mutate }) => {
    const [activePage, setActivePage] = React.useState(null)
    const [confirmDialog, setConfirmDialog] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const navigate = useNavigate();

    const handleDelete = async () => {
        try {

            if (!activePage) {
                toast.error('Invalid page selected')
                return;
            }
            setLoading(true)
            const id = activePage.id;
            const url = `${apiURL}/admin/page/${id}`;

            const { data } = await http.delete(url);

            if (data.state) {
                mutate()
                setConfirmDialog(false)
                setActivePage(null)
            } else {
                throw new Error(data.msg)
            } 

        } catch (err) {
            toast.error(err.message);
        } finally {
            setLoading(false)
        }
    }


    const handleDeleteInit = (page) => {
        setActivePage(page);

        setConfirmDialog(true)

        // handleDelete(page._id)
    }
    return (
        <>

            <TableContainer component={Paper}>
                <ConfirmDialog
                    title="Delete  custom page"
                    content="Are you sure you want to delete this custom page, the action can not be undone"
                    action={<LoadingButton onClick={() => handleDelete} loading={loading}> Delete </LoadingButton>}
                    open={confirmDialog}
                    onClose={() => setConfirmDialog(false)}
                />
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Page Name</TableCell>
                        <TableCell>Page url</TableCell>
                        <TableCell> Page Title </TableCell>
                        {/* <TableCell align="right"> View more </TableCell> */}
                        <TableCell >Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {pages.map((page) => (
                            <TableRow
                            key={page.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{page.name} </TableCell>
                                <TableCell >{page.url}</TableCell>
                                <TableCell >{page.metaTitle}</TableCell>
                                {/* <TableCell> <IconButton onClick={() => handleDelete(page._id)} > <Delete /> </IconButton>  </TableCell> */}
                                <TableCell  align="right"> 
                                <IconButton onClick={() => handleDeleteInit(page)} >
                                    <Delete /> 
                                </IconButton>

                                <IconButton component={Link} to={AppPaths.marketing.customPages.edit(page.id)} >
                                    <Edit />
                                </IconButton>

                                <IconButton onClick={() => navigate(`/custom-pages/details/${page.id}`)}>
                                    <RemoveRedEye />
                                </IconButton>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CustomPagesTable;