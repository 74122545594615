import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import settingsFill from '@iconify/icons-eva/settings-2-outline';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import giftOutline from '@iconify/icons-eva/gift-outline';
import globeOutline from '@iconify/icons-eva/globe-outline';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import bookOpen from '@iconify/icons-eva/book-open-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import cog from '@iconify/icons-eva/settings-2-outline';
import layersOutline from '@iconify/icons-eva/layers-outline';
import shoppingCart from '@iconify/icons-eva/shopping-cart-outline';
import listOutline from '@iconify/icons-eva/list-outline';
import bookOutline from '@iconify/icons-eva/book-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import pantoneOutline from '@iconify/icons-eva/pantone-outline';
import questionMark from '@iconify/icons-eva/question-mark-circle-fill';
import compasFill from '@iconify/icons-eva/compass-fill';



// ----------------------------------------------------------------------

import { AppPaths } from 'routes/paths';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;


const sidebarConfig = 
[
  { 
  subheader: 'general',
  items: [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill),
  },
  {
    title: 'orders',
    path: '/orders',
    icon: getIcon(shoppingCart),
  },
  {
    title: 'products',
    path: '/products',
    icon: getIcon(shoppingBagFill),
    children: [
      { title: 'all products', path: AppPaths.general.products.list },
      { title: 'new product', path: AppPaths.general.products.create },
      // { title: 'featured', path: AppPaths.general.products.featured },
      // { title: 'offers', path: AppPaths.general.products.offers },
      // { title: 'out of stock', path: AppPaths.general.products.outOfStock },
      { title: 'product reviews', path: AppPaths.general.products.reviews },
    ]
  },
  {
    title: 'discount codes',
    path: '/discounts',
    icon: getIcon(giftOutline),
  },
  {
    title: 'brands',
    path: '/brands',
    icon: getIcon(lockFill),
  },
  {
    title: 'categories',
    path: '/categories',
    icon: getIcon(layersOutline),
  },
  {
    title: 'gallery',
    path: '/gallery',
    icon: getIcon(layersOutline),
  },
 
  // {
  //   title: 'profile',
  //   path: '/account',
  //   icon: getIcon(personFill),
  // },
  ]
  },
  {
    subheader: 'marketing',
    items: [
      {
        title: 'Web nav',
        path: '/web-nav',
        icon: getIcon(globeOutline),
      },
      {
        title: 'seo',
        path: '/seo',
        icon: getIcon(globeOutline),
      },
      {
        title: 'custom pages',
        path: '/custom-pages',
        icon: getIcon(bookOutline),
      },
      {
        title: 'shopping guide',
        path: '/shopping-guides',
        icon: getIcon(bookOpen),
      },
      {
        title: 'blog',
        path: '/blog',
        icon: getIcon(fileTextFill),
      },
      {
        title: 'landing pages',
        path: '/landing-pages',
        icon: getIcon(pantoneOutline),
      },
      {
        title: 'testimonials',
        path: AppPaths.marketing.testimonials.list,
        icon: getIcon(compasFill),
      },
      {
        title: 'faqs',
        path: '/faqs',
        icon: getIcon(questionMark),
      },
    ]
  },
  {
    subheader: 'management',
    items: [
       {
        title: 'users',
        path: '/users',
        icon: getIcon(peopleOutline),
      },
      {
        title: 'settings',
        path: '/settings',
        icon: getIcon(settingsFill),
      },
      {
        title: 'system data',
        path: '/data',
        icon: getIcon(listOutline),
      },
    ]
  }
]
;

export default sidebarConfig;
