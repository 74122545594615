import { Container, Grid, Typography } from '@mui/material';
import Page from 'components/Page';
import PageEditor from '../_components/PageEditor';
import { apiURL } from 'common';
import http from 'common/http';
import { useNavigate, useParams } from 'react-router';
import usePageDetail from 'hooks/pages/usePage';
import LoadingScreen from 'components/loading-screen';
import { AppPaths } from 'routes/paths';
import { toast } from 'react-toastify';



const EditCustomPage = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const { page, loading, msg, error, products, mutate } = usePageDetail(id);

    const handleSave = async (postData, setLoader) => {
        try {
         const url = `${apiURL}/admin/page/update/${id}`;
      
         const { data } = await http.put(url, postData);
         
         if (data.state) {
             navigate(AppPaths.marketing.customPages.details(id));
         } else {
             throw(new Error(data.msg));
         }
        } catch (err) {
            toast.error('Operation failed')
            throw(new Error(err.message));
        }
         
     }

     if (loading) {
        return (
            <div sx={{ isolation: 'isolated' }}>
                <LoadingScreen />
            </div>
        )
    }

    // console.log(page)


    return (
    <Page title="Update page">
        <Container>

        <Typography component="div" variant="h4" className="primary_color capitalize-first">
            <Grid container direction="row" justifyContent="space-between"  >
                <Grid item>
                    Update page  
                </Grid>
                {/* <Grid item>
                    <Button variant="contained" color="primary">Add Product</Button>
                    <AddProductToCustomPage page={page} pageId={page._id} mutate={mutate} />
                </Grid> */}
            </Grid>
          </Typography>

          <Grid container sx={{ mt: 5 }}>

                <Grid item xs={12}>
                    <PageEditor initial={page} handleSave={handleSave} />
                </Grid>
            </Grid>
        </Container>
    </Page>)
}

export default EditCustomPage;