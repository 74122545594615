import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(num) {
  // return numeral(number).format(Number.isInteger(number) ? 'KES,0' : 'KES0,0.00');
  if (typeof num !== 'number') return 'Kes 0';

	const f = new Intl.NumberFormat('en-us', {
		currency: 'KES',
		style: 'currency',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
	return f.format(num);
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
